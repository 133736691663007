.rectengal {
  
    flex-shrink: 0;
    background-color: #ffffff;
    border-radius: 30px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    height: 300px;
    padding: 20px;
    
   
    

}

.checkbox{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 70px;
    background-color: #ffffff;
    padding: 10px;
    align-content: center;
}

