@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Comfortaa:wght@300&display=swap');







.lang-change-animation {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1) rotateY(0deg);
  }
  to {
    opacity: 0;
    transform: scale(0.95) rotateY(90deg);
  }
}

/* When the animation ends, bring the content back */
.content:not(.lang-change-animation) {
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95) rotateY(-90deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotateY(0deg);
  }
}


.fadeIn {
  opacity: 1;
  transition: opacity 1s ease-in;
  
}

.hidden {
  opacity: 0;
  transition: opacity 1s ease-out; 
  
}

.animated-grid {
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
  transform: translateX(100%); /* Start off-screen to the right */
  opacity: 0; /* Start fully transparent */
}

.animated-grid_eng {
  transition: opacity 3s ease-in-out, transform 3s ease-in-out;
  transform: translateX(-100%); /* Start off-screen to the right */
  opacity: 0; /* Start fully transparent */
}

.animated-grid.visible {
  transform: translateX(0); /* Move to original position */
  opacity: 1; /* Fade in */
}





  .footer {
    
    bottom: 0; /* stick to the bottom */
    width: 60%;
    opacity: 0.8;
    color: white;  
    text-align: center;
    border-top-width: 0.001rem;
    border-top-style: solid;
    border-top-color: #dbd3d3;
    z-index: 1;
    display: flex;
    justify-content: center;
    margin-left: 20%; 
    margin-right: 20%;
  }

.content {
  position: relative;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  

}

@keyframes shake{
  0% {transform: translate(1px, 0px) rotate(0deg);}
  10% {transform: translate(-1px, 0px) rotate(-1deg);}
  20% {transform: translate(1px, 0px) rotate(1deg);}
  30% {transform: translate(-1px, 0px) rotate(0deg);}
  40% {transform: translate(1px, 0px) rotate(1deg);}
  50% {transform: translate(-1px, 0px) rotate(-1deg);}
  60% {transform: translate(1px, 0px) rotate(0deg);}
  70% {transform: translate(-1px, 0px) rotate(-1deg);}
  80% {transform: translate(1px, 0px) rotate(1deg);}
  90% {transform: translate(-1px, 0px) rotate(0deg);}
  100% {transform: translate(1px, 0px) rotate(-1deg);}
}

@keyframes fadeInOutText {
  0% {
    opacity: 0;
    transform: translateY(10px); /* Start below */
  }
  20% {
    opacity: 1;
    transform: translateY(0); /* Slide into view */
  }
  80% {
    opacity: 1;
    transform: translateY(0); /* Stay in place */
  }
  100% {
    opacity: 0;
    transform: translateY(-10px); /* Fade out and move up */
  }
}

.shake {
  animation: shake 12s ease infinite;
}















